body {
  font-family: "PingFang SC" !important;
}

.header_panel__footer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  background-color: rgba(254, 194, 225, 0.7);
  padding: 8px 16px;
  box-sizing: border-box;
  border: 1px solid #fff;
}

.header_panel {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  max-width: 500px;
  background-color: rgba(254, 194, 225, 0.7);
  padding: 8px 16px;
  box-sizing: border-box;
  border: 1px solid #fff;
}

.header_panel__logo {
  display: flex;
  width: 52px;
}
.header_panel__logo-wrapper {
  display: flex;
}
.header_panel__button {
  display: flex;
  width: 110px;
}

.image {
  width: 100%;
}

.main_button {
  max-width: 250px;
  display: flex;
  margin-bottom: 24px;
}

.header_panel__text {
  margin-left: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
